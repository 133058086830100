import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';

const StyledSection = styled.section`
  margin-top: 5rem;
  padding: 7rem 0 50px;
  ${media.tablet} {
    margin-top: 0;
  }
  ${media.mobile} {
    margin-top: 0;
    padding: 120px 0 50px;
    padding-bottom: 0;
  }
  & h1 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 42px;
    ${media.mobile} {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 24px;
    }
    & strong {
      font-weight: 800;
    }
  }
  .desc {
    font-size: 22px;
    line-height: 36px;
    font-weight: 400;
    width: 95%;
    margin-bottom: 56px;
    ${media.mobile} {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  padding-left: 22px;
  ${media.mobile} {
    padding-left: 24px;
  }
  & li {
    position: relative;
    margin-top: 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    ${media.mobile} {
      font-size: 16px;
      line-height: 24px;
      margin-top: 24px;
    }
    & strong {
      font-weight: 800;
    }
    &::before {
      content: '';
      position: absolute;
      top: 11px;
      left: -22px;
      width: 8px;
      height: 8px;
      background-color: #0a4ed6;
      border-radius: 50%;
      ${media.mobile} {
        top: 8px;
      }
    }
  }
`;

const Box = styled.div`
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 42px 32px 32px;
  height: fit-content;
  @media (max-width: 991px) {
    margin-top: 56px;
  }
  ${media.mobile} {
    padding: 24px;
    margin: 56px 16px 0;
  }

  & h2 {
    font-size: 26px;
    margin-bottom: 34px;
    font-weight: 800;
    ${media.mobile} {
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
  & p {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    ${media.mobile} {
      font-size: 16px;
      margin-bottom: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    & a {
      font-size: 22px;
      font-weight: 800;
      ${media.mobile} {
        font-size: 18px;
      }
    }
  }
`;

const GuestPost = () => {
  return (
    <Layout
      canonical="/blog/guest-post/"
      metaTitle="Guest Blogging Guidelines – LiveSession Blog"
      metaDescription="Contribute to the LiveSession blog. Share your unique insights on UX, CRO and analytics."
    >
      <StyledSection>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-8">
              <h1>
                Guest Posting <strong>Guidelines</strong>
              </h1>
              <p className="desc">
                We accept guest post submissions for the LiveSession blog. Please read our
                requirements before you get in touch:
              </p>
              <StyledList>
                <li>
                  We publish original, insightful pieces about{' '}
                  <strong>
                    UX design and research, analytics and conversion rate optimization.
                  </strong>
                </li>
                <li>
                  Keep in mind that we’re checking every piece thoroughly to{' '}
                  <strong>avoid plagiarism.</strong>
                </li>
                <li>
                  Stick to a <strong>casual, conversational</strong> writing style.
                </li>
                <li>
                  Please provide <strong>examples</strong> and <strong>use cases.</strong>
                </li>
                <li>
                  <strong>Case studies and hands-on insights</strong> are particularly welcome.
                </li>
                <li>
                  The minimum volume for a guest post is <strong>1500 words.</strong>
                </li>
                <li>
                  The article will be posted <strong>exclusively</strong> on the LiveSession blog.
                </li>
                <li>
                  <strong>Only one backlink</strong> to your company’s website is allowed in the
                  guest post.
                </li>
              </StyledList>
            </div>
            <Box className="col-lg-4">
              <div className="container">
                <h2>Request posting</h2>
                <p>
                  If you feel that your contribution could be valuable for us, please get in touch
                  via email:
                </p>
                <p>
                  <a href="mailto:hello@livesession.io">hello@livesession.io</a>
                </p>
              </div>
            </Box>
          </div>
        </div>
      </StyledSection>
      <StartUsing addRadius />
    </Layout>
  );
};

export default GuestPost;
